import { useEffect, useState } from "react";
import RIGHT from "../../../assets/image/Game/Pickup/right.png";
import LEFT from "../../../assets/image/Game/Pickup/left.png";
import ICE from "../../../assets/image/Game/Pickup/ice.png";
import "../../../assets/css/Pickup.css";
import BackgroundGame from "../../../components/BackgroundPopup/BackgroundGame";
import PickupItem from "./PickupItem";
import { LUOT_QUAY } from "../../../services/localService/localService";
import {
  GameDetailsModel,
  ListGameCampaignModel,
} from "../../../models/GameModel";
import { useNavigate, useParams } from "react-router-dom";
import { GameServices } from "../../../services/apiService/GameService";
import PopupReceiveGift from "../../../components/Popup/PopupGift";
import PopupError from "../../../components/Popup/PopupError";

export default function Pickup() {
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [current_number, set_current_number] = useState<number>(3);
  const [animation, setAnimation] = useState<boolean>(false);
  const [random_location, set_random_location] = useState<number[]>([]);
  const [gap_active, setGap_Active] = useState<boolean>(false);
  const [open_gap, set_open_gap] = useState<boolean>(false);
  const [pre_number, set_pre_number] = useState<string>("no");
  const [number_gap, setNumber_Gap] = useState<number>(0);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const [popUpGift, setPopupGift] = useState("no");
  const [spinRemain, setSpinRemain] = useState<number>(0);
  const [game, setDataGame] = useState<GameDetailsModel>();
  let arr = [0, 1, 2, 3, 4];
  const navigation = useNavigate();
  const { campaign_id, sale_id } = useParams();
  useEffect(() => {
    const dataLocal = JSON.parse(
      localStorage.getItem("HOME_DATA_LOCAL") || "{}"
    );
    console.log(dataLocal);
    const shuffledArr = arr.sort(() => Math.random() - 0.5);
    set_random_location(shuffledArr);
    const filterGame = dataLocal?.games?.filter(
      (x: ListGameCampaignModel) => x.campaign_id.toString() === campaign_id
    );
    console.log(filterGame);
    getCalculator(sale_id, campaign_id);
    console.log(1);
  }, []);
  const handleCloseError = () => {
    navigation("/games");
  };
  const handleRight = () => {
    if (current_number === 5) {
    } else {
      set_pre_number("right");
      set_current_number(current_number + 1);
    }
  };
  const handleDisable = () => {};
  const handleLeft = () => {
    if (current_number === 1) {
    } else {
      set_pre_number("left");
      set_current_number(current_number - 1);
    }
  };
  const hanleAniGap = () => {
    setAnimation(true);
    setDisableBtn(true);
    // setTimeout(() => {
    runWheel();
    // }, 3000);
    setTimeout(() => {
      set_open_gap(true);
      setTimeout(() => {
        setNumber_Gap(current_number);
        set_open_gap(false);
        setGap_Active(true);
      }, 400);
    }, 1300);
    setTimeout(() => {
      setPopupGift("open");
      setIsPopup(true);
      console.log("hello");
    }, 3600);
  };
  const [lineId, setLineId] = useState();
  const [gift, setGift] = useState<any>();
  useEffect(() => {
    setGap_Active(false);
    setAnimation(false);
    set_open_gap(false);
    setDisableBtn(false);
    set_current_number(3);
    setNumber_Gap(0);
    set_pre_number("no");
    const shuffledArr = arr.sort(() => Math.random() - 0.5);
    set_random_location(shuffledArr);
    console.log("hello cho nay ne");
    getCalculator(sale_id, campaign_id);
  }, [popUpGift === "change"]);
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setPopupGift("no");
          setDataGame(res?.lucky_draw_list?.game_data);
          console.log(res);
          setSpinRemain(res.remaining_draw);
          const countGiftWin = res.total_draw - res.remaining_draw;
          console.log(countGiftWin);
          const giftWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          console.log(giftWin);
          setGift(giftWin);
          setLineId(giftWin?.line_id);
          localStorage.setItem(
            "LIST_LUCKY_DRAW",
            JSON.stringify(res.lucky_draw_list.list_lucky_draw)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(giftWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };

  const runWheel = () => {
    console.log("ấn vào quay");
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };
    console.log(data);
    GameServices.CountDrawApi(data)
      .then((res: any) => {
        console.log("trả data:", res);
        console.log(spinRemain);
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        setSpinRemain(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.error(err);
      });
  };
  const handleAgree = () => {
    const luot_quay = localStorage.getItem(LUOT_QUAY);
    if (luot_quay === "0") {
      navigation("/list-gift");
    } else {
      setPopupGift("change");
      setIsPopup(false);
    }
  };

  return (
    <div className="">
      {game && (
        <BackgroundGame img={game?.background_image_url}>
          <div className="absolute top-0 bg-game-ufo w-10/12 left-1/2 -translate-x-1/2">
            <div className="relative top-16 bg-game-img w-full px-6">
              <div className="relative z-10">
                <img
                  src={game?.machine_header_image_url}
                  loading="lazy"
                  className="w-full"
                />
              </div>
              <div className="relative body-hide -top-[2px]">
                <img
                  src={game?.machine_body_image_url}
                  loading="lazy"
                  className="w-full"
                />

                <PickupItem
                  animation={animation}
                  current_number={current_number}
                  gap_active={gap_active}
                  open_gap={open_gap}
                  pre_number={pre_number}
                  number_gap={number_gap}
                  random_location={random_location}
                  game={game}
                />
                <img src={ICE} className="w-full absolute bottom-10 px-10" />
              </div>
              <div className="relative -top-[2px] z-50">
                <img
                  src={game?.machine_footer_image_url}
                  loading="lazy"
                  className="w-full z-20"
                />
                <div className="absolute top-0 w-full px-12 z-50">
                  <div className="grid grid-cols-3 relative z-50">
                    <div className="flex justify-center relative z-50">
                      <div>
                        <button
                          className="pushable-lr label-transx relative z-50"
                          onClick={
                            disableBtn === true
                              ? () => handleDisable()
                              : handleLeft
                          }
                        >
                          <span id="" className="front-lr relative z-50">
                            <img src={RIGHT} className="mt-1 relative z-50" />
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-center relative z-50">
                      <div>
                        <button
                          className="pushable label-transx relative z-50"
                          onClick={
                            disableBtn === true
                              ? () => handleDisable()
                              : () => hanleAniGap()
                          }
                        >
                          <span id="" className="front relative z-50">
                            OK
                          </span>
                        </button>
                      </div>
                    </div>

                    <div className="flex justify-center relative z-50">
                      <div>
                        <button
                          className="pushable-lr label-transx relative z-50"
                          onClick={
                            disableBtn === true
                              ? () => handleDisable()
                              : handleRight
                          }
                        >
                          <span id="" className="front-lr">
                            <img src={LEFT} className="mt-1 relative z-50" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {spinRemain === 0 ? (
                  <div className="absolute z-50 text-[20px] top-12 left-1/2 -translate-x-1/2 text-white w-full text-center">
                    Hết lượt chơi
                  </div>
                ) : (
                  <div className="absolute z-50 text-[20px] top-12 left-1/2 -translate-x-1/2 text-white w-full text-center">
                    Bạn còn {spinRemain} lượt gắp
                  </div>
                )}
              </div>
            </div>
          </div>
          <PopupReceiveGift
            gift={gift}
            isShow={isPopup}
            handleAgree={handleAgree}
          />
          <PopupError
            labelBtn={"Đồng ý"}
            messError={messError}
            handleClose={handleCloseError}
            isShow={isError}
          />
        </BackgroundGame>
      )}
    </div>
  );
}
