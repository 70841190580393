import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BACK from "../../assets/image/Header/back-header.png";
import { LeaderBoardServices } from "../../services/apiService/LeaderBorad";
import HeaderFrameLeaderBoard from "../../components/HeaderFrame/HeaderFrameLeaderBoard";
import RewardsLeaderBoard from "../../components/RewardsLeaderBoard/RewardsLeaderBoard";

export default function ListPrizeBySeasons() {
  const navigation = useNavigate();
  const { id, type } = useParams();
  const [current, setCurrent] = useState("1");
  const [typeGift, setDataTypeGift] = useState("week");
  const [totalPrize, setTotalPrize] = useState<number>(0);
  const [listBoard, setListBoard] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const handleD = (data: number) => {
    setPage(data);
  };
  const handleBack = () => {
    navigation(`/prize-game/${id}`);
  };
  const hanldeClick = (e: any, status_gifts: boolean) => {
    setCurrent(e.target.id);
    if (status_gifts) {
      setDataTypeGift("week");
      getPrizeCampaign(id as string, "week");
    } else {
      setDataTypeGift("month");
      getPrizeCampaign(id as string, "month");
    }
  };
  const getPrizeCampaign = (id: string, race_type: string) => {
    LeaderBoardServices.Race(id as string, race_type)
      .then((res: any) => {
        setListBoard(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getPrizeCampaign(id as string, "week");
    setCurrent("1");
  }, []);

  return (
    <div>
      <HeaderFrameLeaderBoard
        title="Danh sách trúng thưởng"
        handleBack={handleBack}
        iconBack={BACK}
        footer=""
        changeBackgroundColor="bg-[#DEFAFD]"
      >
        <RewardsLeaderBoard
          isDisableButton={false}
          title1="Tuần"
          title2="Tháng"
          handleD={handleD}
          page={page}
          totalPrize={totalPrize}
          typeGift={typeGift}
          current={current}
          hanldeClick={hanldeClick}
          listBoard={listBoard}
        />
      </HeaderFrameLeaderBoard>
    </div>
  );
}
