import ICON_TICHDIEM from "../../../assets/image/Home/icon-tichdiem.png";
import ICON_DOIDIEM from "../../../assets/image/Home/icon-doidiem.png";
import ICON_LAMNHIEMVU from "../../../assets/image/Home/icon-lamnhiemvu.png";
import ICON_TREE from "../../../assets/image/Home/icon-tree.png";
import ICON_NEW from "../../../assets/image/Home/new.png";
import { useNavigate } from "react-router-dom";
import { BACK_GLOBAL } from "../../../services/localService/localService";
import { homeServices } from "../../../services/apiService/homeService";
import { useEffect, useState } from "react";
import PopupLogin from "../../../popup/PopupLogin";
import CLOSE from "../../../assets/image/Home/cancel-popup.png";

export default function ControlBar({
  numberRotation,
}: {
  numberRotation: any;
}) {
  console.log(numberRotation);
  const navigation = useNavigate();

  useEffect(() => {
    homeServices.homeApi().then((res: any) => {
      console.log(res);
      if (res?.request_update_info) {
        navigation("/update-profile");
      }
    });
  }, []);
  const handleRedirect = (e: string) => {
    navigation(`/${e}`);
    localStorage.setItem(BACK_GLOBAL, e);
  };
  const handleCheck = (e: string) => {
    homeServices
      .GetProfile()
      .then((res: any) => {
        console.log(res);
        navigation(`/${e}`);
      })
      .catch((err) => {
        console.log(err);
      });

    localStorage.setItem(BACK_GLOBAL, e);
  };
  const [isShowPopupT, setIsShowPopupT] = useState(false);
  const handleJoin = () => {
    setIsShowPopupT(false);
    document.body.style.overflow = "unset";
    navigation("/guide-page-picture/0");
  };
  const handleClose = () => {
    setIsShowPopupT(false);
  };
  const handleRedirectGuide = (e: string) => {
    setIsShowPopupT(true);
  };

  return (
    <div className="px-5">
      <div
        className={`${
          numberRotation?.periodic_data?.periodic_id
            ? "grid-cols-4 gap-4"
            : " grid-cols-3 gap-10 my-2"
        } grid my-2`}
      >
        <div
          className="bg-[#59C2DD] p-[2px] rounded-full shadow-navcontrol border-bar"
          onClick={() => handleRedirectGuide("guide-page-picture/0")}
        >
          <div className="bg-[#F1F2F3] bg-navtab">
            <img src={ICON_TICHDIEM} className="w-full" />
          </div>
          <div className="h-14 flex justify-center items-center text-[12px] text-[#F2F5F9] font-light-so">
            Tích điểm
          </div>
        </div>
        {numberRotation?.periodic_data?.periodic_id && (
          <div
            className="bg-[#59C2DD] p-[2px] rounded-full shadow-navcontrol border-bar relative"
            onClick={() =>
              handleRedirect(
                `join_plant_tree/${numberRotation?.periodic_data?.periodic_id}`
              )
            }
          >
            <div className="absolute top-1/2 -translate-y-1/2 -left-1">
              <img src={ICON_NEW} />
            </div>
            <div className="bg-[#F1F2F3] bg-navtab">
              <img src={ICON_TREE} className="w-full" />
            </div>
            <div className="h-14 flex justify-center items-center text-[12px] text-[#F2F5F9] font-light-so">
              Nhiệm vụ
              <br /> trồng cây
            </div>
          </div>
        )}
        <div
          className="bg-[#59C2DD] p-[2px] rounded-full shadow-navcontrol border-bar"
          onClick={() => handleRedirect("exchange-point")}
        >
          <div className="bg-[#F1F2F3] bg-navtab">
            <img src={ICON_DOIDIEM} className="w-full" />
          </div>
          <div className="h-14 leading-4 flex justify-center items-center text-[12px] text-[#F2F5F9] font-light-so">
            Đổi điểm
            <br />
            nhận quà
          </div>
        </div>
        <div
          className="bg-[#59C2DD] p-[2px] rounded-full shadow-navcontrol border-bar relative"
          onClick={() => handleCheck("games")}
        >
          <div className="bg-[#F1F2F3] bg-navtab">
            <img src={ICON_LAMNHIEMVU} className="w-full" />
          </div>
          <div className="h-14 text-center leading-4 flex justify-center items-center text-[12px] text-[#F2F5F9] font-light-so">
            Lượt chơi
            <br /> của bạn
          </div>
          {numberRotation?.remaining_draw > 0 && (
            <div className="absolute -top-2 right-1 text-[13px] w-6 h-6 bg-rotation text-white font-semibold-so flex justify-center items-center">
              {numberRotation?.remaining_draw}
            </div>
          )}
        </div>
      </div>
      <PopupLogin isShow={isShowPopupT}>
        <div className="py-5 px-5 text-center relative font-regular-so">
          <div className="uppercase text-[#2D4E68] text-[28px] font-bold-so">
            Thông báo
          </div>
          <div className="text-left">
            - Hình hóa đơn tham gia chương trình chỉ áp dụng trong vòng{" "}
            <span className="text-[#223F95] font-semibold-so">
              03 ngày kể từ thời điểm xuất hóa đơn.
            </span>{" "}
            <br />- Mỗi khách hàng chỉ được đổi quà bằng Pepcoin{" "}
            <span className="text-[#223F95] font-semibold-so">
              tối đa 3 lần/ngày.
            </span>{" "}
            <br />- Mỗi khách hàng chỉ được nhận được{" "}
            <span className="text-[#223F95] font-semibold-so">
              tối đa 500 Pepcoin/tháng.
            </span>
          </div>
        </div>
        <div className="pb-5">
          <button
            className="bg-[#5BC2DC] px-14 py-4 rounded-2xl shadow-item text-white"
            onClick={handleJoin}
          >
            Đồng ý
          </button>
        </div>
        <div className="absolute -bottom-20 left-1/2 -translate-x-1/2">
          <img src={CLOSE} onClick={handleClose} />
        </div>
      </PopupLogin>
    </div>
  );
}
