import { useEffect, useRef, useState } from "react";
import { Camera } from "../../components/Camera";
import { getOS } from "../../utils/deviceModel";
import "../../assets/css/GuideTakePhoto.css";
import {
  SET_CHECK_CAM,
  userLocal,
} from "../../services/localService/localService";
import { useNavigate, useParams } from "react-router-dom";
import uuid from "react-uuid";
import { handleChangeImage } from "../../utils/compressImage";
import RIGHTTOP from "../../assets/image/GuideTakePhoto/righttop.png";
import LEFTTTOP from "../../assets/image/GuideTakePhoto/lefttop.png";
import RIGHTBOTTOM from "../../assets/image/GuideTakePhoto/rightbottom.png";
import LEFTBOTTOM from "../../assets/image/GuideTakePhoto/leftbottom.png";
import CLOSE from "../../assets/image/GuideTakePhoto/close.png";
import QA from "../../assets/image/GuideTakePhoto/qa.png";
import { format } from "date-fns";
import { URL_API_UPLOAD_GCS, apiNodeJS } from "../../services/api/apiNode";
import { homeServices } from "../../services/apiService/homeService";
import PopupGiftSubmitReceipt from "../../components/Popup/SubmitReceipt/PopupGiftSubmitReceipt";
import Photo from "./Photo";
import PopupError from "../../components/Popup/PopupError";
import { setAuthorization } from "../../services/apiService/configURL";
import Waiting from "../../components/Popup/Waiting";
import ICON_QR from "../../assets/image/Home/icon-qr.json";
import Lottie from "react-lottie";
import ErrorBill from "./UrgentPageReviewBill/ErrorBill";
import PopupConfirm from "./UrgentPageReviewBill/PopupConfirm";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: ICON_QR,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function GuideTakeAPhoto() {
  const camera = useRef(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const { id } = useParams();
  console.log(id);

  const navigation = useNavigate();
  const [activeDeviceId, setActiveDeviceId] = useState(undefined);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [messError, setMessError] = useState("");
  const [devices, setDevices] = useState([]);
  const [openCam, setOpenCam] = useState(true);
  const [image, setImage] = useState(undefined);
  const [imageFile, setImageFile] = useState(undefined);
  const [current, setCurrent] = useState("0");
  const [isAskLogin, setIsAskLogin] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const [listReceipt, setListReceipt] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ocrMethod, setOcrMethod] = useState();
  const os = getOS();
  let { token } = userLocal.get();
  setAuthorization(token);
  useEffect(() => {
    window.scrollTo(0, 0);
    setHeight(window.screen.availHeight);
    setWidth(window.screen.availWidth);
    getDeviceId();
    localStorage.removeItem("REVIEW_IMG");
  }, []);
  useEffect(() => {
    homeServices
      .ocrEndPointApi(id !== undefined ? id : 0)
      .then((res) => {
        console.log(res);
        setOcrMethod(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  const getDeviceId = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((i) => i.kind == "videoinput");
    const font = ["Webcam", "back", "Camera mặt sau", "Back", "cực rộng"];
    const matching = videoDevices.filter((l) => {
      return font.some((term) => l.label.includes(term));
    });
    setDevices(matching?.reverse());
  };
  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  useEffect(() => {
    if (os !== "iOS") {
      setTimeout(() => {
        setActiveDeviceId(devices[0]?.deviceId);
      }, 1050);
    }
  }, [devices]);
  const handleIndex = (id, index) => {
    setActiveDeviceId(id);
    setCurrent(index);
  };
  const handleCancelCam = () => {
    localStorage.removeItem(SET_CHECK_CAM);
    setImageFile(undefined);
    setImage(undefined);
    setOpenCam(false);
    setIsAskLogin(false);
    navigation(`/`);
  };
  useEffect(() => {
    if (image !== undefined) {
      urltoFile(image, uuid() + uuid() + ".jpg", "image/jpeg").then(function (
        file
      ) {
        const a = handleChangeImage(file);
        console.log(a);
        setImageFile(a);
      });
    }
  }, [image]);
  const [offPopup, setOffPopup] = useState(true);
  const [imgError, setImgError] = useState();
  const pushImageToGCS = () => {
    setIsWaiting(true);
    setOffPopup(false);
    console.log(imageFile);
    let formDataGCS = new FormData();
    formDataGCS.append("file", imageFile);
    const fileName =
      uuid() +
      "_" +
      format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
      "_" +
      imageFile.name;
    formDataGCS.append("fileName", fileName);
    formDataGCS.append(
      "ocrBase",
      ocrMethod?.ocr_endpoint
        ? ocrMethod?.ocr_endpoint
        : "http://ec2-13-250-133-136.ap-southeast-1.compute.amazonaws.com/api/ocr/scan?sm=auto"
    );
    apiNodeJS
      .postUploadToNode(URL_API_UPLOAD_GCS, formDataGCS)
      .then((res) => {
        console.log(res);
        const params = {
          ocr_result: `{}`,
          ocr_endpoint: ocrMethod?.ocr_endpoint
            ? ocrMethod?.ocr_endpoint
            : "http://ec2-13-250-133-136.ap-southeast-1.compute.amazonaws.com/api/ocr/scan?sm=auto",
          gsutil_url: res?.gsutil_url,
          public_url: res?.public_url,
          ocr_method: ocrMethod?.ocr_method,
          request_id: uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss"),
          receipt_datetime: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        };
        setImgError(res?.public_url);
        console.log(params);

        homeServices
          .submitReceiptApi(params)
          .then((res) => {
            console.log(res);
            localStorage.setItem("REVIEW_IMG", params?.public_url);
            localStorage.setItem("SALE_ID", res?.sale_id);
            if (res?.auto) {
              setConfirm(true);
              setListReceipt(res);
            } else {
              navigation(`/countdown`);
            }
          })
          .catch((err) => {
            console.log(err);
            setMessError(err);
            setIsError(true);
          })
          .finally((err) => {
            console.log(err);
            setIsWaiting(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onClickDeleteImg = (e) => {
    setImageFile(undefined);
    setImage(undefined);
    setOpenCam(true);
  };
  const handleClose = () => {
    document.body.style.overflow = "unset";
    setIsError(false);
    setOpenCam(true);
    navigation("/");
  };
  const handleGuide = () => {
    navigation(`/guide-page-picture/${id ? id : 0}`);
  };
  return (
    <div>
      {/* {isLoading && <Loading />} */}
      {isWaiting ? <Waiting /> : null}
      {openCam ? (
        <div className="relative">
          <Camera
            ref={camera}
            aspectRatio={
              activeDeviceId
                ? 8 / 15
                : os === "iOS"
                ? width < 392
                  ? 8 / 15
                  : 7 / 13
                : 8 / 15
            }
            videoSourceDeviceId={activeDeviceId}
            facingMode="environment"
            errorMessages={{
              noCameraAccessible: "",
              permissionDenied:
                "Permission denied. Please refresh and give camera permission.",
              switchCamera: "",
              canvas: "Canvas is not supported.",
            }}
            videoReadyCallback={() => {
              console.log("Video feed ready.");
            }}
          />
          <div className="bg-button-camera absolute w-full z-50">
            <div
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
              className="relative z-0 flex justify-center items-center opacity-90 "
            >
              <div className="absolute max-[389px]:top-3 -top-6 left-7">
                <button
                  className="font-light-so bg-[#81BEB4] border-qa flex justify-center items-center text-white opacity-100  w-16 h-16 rounded-full text-[18px]"
                  onClick={handleGuide}
                >
                  <img src={QA} className="w-5" />
                </button>
                <div className="text-center text-white text-[12px] mt-1">
                  Hướng dẫn
                </div>
              </div>
              <button
                className="border-base btn-webcam absolute w-full max-[389px]:top-0 -top-9 z-50 opacity-100 shadow-navcontrol"
                onClick={() => {
                  if (camera.current) {
                    setOpenCam(false);
                    const photo = camera.current.takePhoto();
                    console.log(photo);
                    setImage(photo);
                  }
                }}
              >
                <div className="bg-[#5BC2DC] relative rounded-full w-full">
                  <Lottie options={defaultOptions} height={50} width={50} />
                  <div className="absolute text-[11px] -bottom-8 w-40 left-1/2 -translate-x-1/2">
                    Quét hóa đơn
                  </div>
                </div>
              </button>
              <div className="absolute max-[389px]:top-3 -top-6 right-7">
                <button
                  className="font-light-so border-qa bg-[#2D4E68] flex justify-center items-center text-white opacity-100  w-16 h-16 rounded-full text-[18px]"
                  onClick={handleCancelCam}
                >
                  <img src={CLOSE} className="w-5" />
                </button>
                <div className="text-center text-white text-[12px] mt-1">
                  Đóng
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-100 bg-transparent absolute top-2 w-full">
            <img src={RIGHTTOP} className="absolute right-5" />
            <img src={LEFTTTOP} className="absolute left-5" />
            <img
              src={RIGHTBOTTOM}
              className="absolute right-5 -bottom-[580px] max-[380px]:-bottom-[550px]"
            />
            <img
              src={LEFTBOTTOM}
              className="absolute left-5 -bottom-[580px] max-[380px]:-bottom-[550px]"
            />
          </div>

          {os === "iOS" ? null : (
            <div className="w-full flex justify-center">
              <div className="relative flex justify-between items-center w-28 bottom-44 px-4 rounded-3xl opacity-50">
                {devices.map((d, index) => (
                  <div
                    key={index}
                    className={`${
                      parseInt(current) === index ? "bg-white" : ""
                    } text-[12px] w-8 rounded-2xl h-8 flex justify-between 
                      items-center opacity-100`}
                  >
                    <div className="flex justify-center flex-auto ">
                      <button
                        onClick={() => handleIndex(d.deviceId, index)}
                        className={`${
                          parseInt(current) === index
                            ? "text-black"
                            : "text-white"
                        } font-bold-mon opacity-100`}
                      >
                        {d.label.includes("camera2 2")
                          ? "0.5x"
                          : d.label.includes("camera2 0")
                          ? "1x"
                          : "2x"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {offPopup && (
            <Photo
              image={image}
              onClickDeleteImg={(e) => onClickDeleteImg(e)}
              pushImageToGCS={pushImageToGCS}
            />
          )}
        </>
      )}

      <ErrorBill
        title={"Hóa đơn không hợp lệ"}
        isShow={isError}
        messError={messError}
        handleClose={handleClose}
        labelBtn="Đồng ý"
        imageError={imgError}
      />
      <PopupConfirm
        isShow={isConfirm}
        listReceipt={listReceipt}
        setConfirm={setConfirm}
      />
    </div>
  );
}
