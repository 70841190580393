import { NavChooseHome } from "../../models/HomePage";
import ICON_NEXT from "../../assets/image/Home/icon-next.png";
import { useLocation, useNavigate } from "react-router-dom";
import { locationBefore } from "../../services/localService/localService";

export default function ChooseHome({
  icon,
  name,
  value,
  navigate,
}: NavChooseHome) {
  const { pathname } = useLocation();
  const navigation = useNavigate();

  const handleNavigation = () => {
    navigation(navigate);
    locationBefore.set(pathname);
  };
  return (
    <button
      className="flex justify-between items-center w-full"
      onClick={handleNavigation}
    >
      <div className="flex items-center">
        <div className="mr-3">
          <img src={icon} loading="lazy" width={20} height={20} alt="Image" />
        </div>
        <div className="text-[21px] font-semibold-so text-[#4E9ABB] uppercase">
          {name}
        </div>
      </div>
      <div className="mt-[1px] flex items-center font-light-so text-[12px] text-[#2D4E68]">
        <div>{value}</div>
        <div className="ml-3">
          <img src={ICON_NEXT} width={6} loading="lazy" alt="Image" />
        </div>
      </div>
    </button>
  );
}
