import { axiosInstance } from "./configURL";

export const GameServices = {
  GameDetailsApi: (campaign_id: string, token: string) => {
    return axiosInstance.get(`/campaign/info?campaign_id=${campaign_id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  },
  ListRotation: (status: string, page: number, limit: number) => {
    return axiosInstance.get(
      `/campaign/lucky_draw/list?status=${status}&page=${page}&limit=${limit}`
    );
  },
  validateTime: () => {
    return axiosInstance.get(`/campaign/validate_time`);
  },
  CalculatorWheelApi: (data: any) => {
    return axiosInstance.post(
      `/campaign/lucky_draw/calculate`,
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  CountDrawApi: (data: any) => {
    return axiosInstance.post(
      `/campaign/lucky_draw/count`,
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  SendGiftApi: (data: any) => {
    return axiosInstance.post(
      `/campaign/lucky_draw/send`,
      {
        params: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  GetGamePlantTree: (id: string) => {
    return axiosInstance.get(`/campaign/periodic/info?periodic_id=${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  GetJoinGamePlantTree: (id: string) => {
    return axiosInstance.post(
      `/campaign/periodic/join`,
      {
        params: {
          periodic_id: id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  UpdateUseWater: (id: string) => {
    return axiosInstance.post(
      `/campaign/periodic/use_exp`,
      {
        params: {
          periodic_id: id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  ListQuest: (id: string) => {
    return axiosInstance.get(`/campaign/periodic/quest?periodic_id=${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  UpdateQuest: (id: string, quest_id: string) => {
    return axiosInstance.post(
      `/campaign/periodic/doing_quest`,
      {
        params: {
          periodic_id: id,
          quest_id: quest_id,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
  GetLeaderBoard: (id: string, top: string) => {
    return axiosInstance.get(
      `/campaign/periodic/leaderboard?periodic_id=${id}&top=${top}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};
