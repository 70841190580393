import { isMobileDevice } from "../../utils/deviceModel";
import ErrorBill from "../../assets/image/desktop.png";

export default function NoAccessBrowser() {
  let isMobile = isMobileDevice();

  const backgroundMobile = {
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "40vh",
    paddingLeft: "15px",
    paddingRight: "15px",
    backgroundColor: "#5BC2DC",
  };
  return (
    <div>
      {isMobile ? (
        <div style={backgroundMobile}>
          <div className="container">
            <div style={{ textAlign: "center" }}>
              <div>
                <div className="noaccess-content">
                  Bạn vui lòng truy cập ứng dụng trên trình duyệt (Không phải ẩn
                  danh) Chrome hoặc Safari của thiết bị di động. Nhấn vào dấu
                  “…” ở góc phải màn hình xong chọn “Mở bằng trình duyệt” để
                  tiếp tục tham gia chương trình.
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full relative bg-desktop">
          <div className="relative h-full flex justify-center items-center bg-cove min-h-screen">
            <img
              src={ErrorBill}
              className="absolute top-[50%] -translate-y-1/2"
            />
          </div>
        </div>
      )}
    </div>
  );
}
