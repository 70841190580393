import { useEffect, useState } from "react";
import "../../../assets/css/SpinWheel.css";
import BackgroundGame from "../../../components/BackgroundPopup/BackgroundGame";
import { useNavigate, useParams } from "react-router-dom";
import {
  LUOT_QUAY,
  userLocal,
} from "../../../services/localService/localService";
import PopupReceiveGift from "../../../components/Popup/PopupGift";
import { setAuthorization } from "../../../services/apiService/configURL";
import { GameServices } from "../../../services/apiService/GameService";
import { GameDetailsModel } from "../../../models/GameModel";
import Loading from "../../../components/Loading/Loading";
import PopupError from "../../../components/Popup/PopupError";

export default function SpinWheel() {
  const [game, setBackgroundGame] = useState<GameDetailsModel>();
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [isAnnounce, setIsAnnounce] = useState<boolean>(false);
  const [transformBox, settransformBox] = useState<string>("");
  const [dataCallUrbox, setDataCallUrbox] = useState<number>(0);
  const [gift, setGift] = useState<any>();
  const [win, setwin] = useState(0);
  const [isError, setIsError] = useState<boolean>(false);
  const [messError, setMessError] = useState<string>("Error");
  const [segments, setsegments] = useState<any>();
  const [render, setRender] = useState<boolean>(false);
  const [isOpenPopup, setIsPopup] = useState<boolean>(false);
  const [lineId, setLineId] = useState();
  const [popUpGift, setPopupGift] = useState("no");
  let navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { campaign_id, sale_id } = useParams<string>();
  let { token } = userLocal.get();
  const [isLoadButton, setIsLoadButton] = useState(false);
  setAuthorization(token);
  useEffect(() => {
    setIsLoading(true);
    getCalculator(sale_id, campaign_id);
  }, [popUpGift === "change"]);
  useEffect(() => {
    setAuthorization(token);
    setIsLoading(true);
    getCalculator(sale_id, campaign_id);
  }, [render]);
  const interleave: any = ([x, ...xs]: any, ys: any) =>
    x ? [x, ...interleave(ys, xs)] : ys;
  const [isDisable, setIsDisable] = useState(false);
  const runWheel = () => {
    setIsDisable(true);
    if (!isDisable) {
      setIsSpinning(true);
      var array_toado = [
        { id: 1, toado: 20 },
        { id: 2, toado: -25 },
        { id: 3, toado: -70 },
        { id: 4, toado: -115 },
        { id: 5, toado: -160 },
        { id: 6, toado: -205 },
        { id: 7, toado: -250 },
        { id: 8, toado: -295 },
      ];
      console.log(typeof win);
      console.log(typeof win);
      if (win !== 0) {
        console.log("hello");
        console.log(win);
        var item = array_toado?.find((i) => i.id === win);
        let toado: number | undefined = item?.toado;
        console.log(toado);
        settransformBox("rotate(" + ((toado as number) + 360 * 6) + "deg)");
        var element = document.getElementById("mainbox") as HTMLElement;
        countDraw();
        setTimeout(function () {
          element.classList.remove("animate");
          setIsSpinning(false);
          redirectToWheelResult(); // set qua lun phần thưởng ko dừng
          setIsPopup(true);
        }, 6000);
      }
    }
  };
  const redirectToWheelResult = () => {
    settransformBox("rotate(" + 0 + "deg)");
    setIsAnnounce(!isAnnounce);
    console.log("hello");
  };
  const getCalculator = (
    sale_id: string | undefined,
    campaign_id: string | undefined
  ) => {
    const params = {
      sale_id: sale_id,
      campaign_id: campaign_id,
    };
    GameServices.CalculatorWheelApi(params)
      .then((res: any) => {
        console.log(res);
        if (res.remaining_draw === 0) {
          setIsError(true);
          setMessError("Bạn đã hết lượt chơi !!!");
        } else {
          setPopupGift("no");
          setDataCallUrbox(res.remaining_draw);
          setsegments(res.lucky_draw_list.list_lucky_draw[0].list_draw);
          setBackgroundGame(res.lucky_draw_list.game_data);
          let itemWin = res.lucky_draw_list.list_lucky_draw[0].list_win[0];
          setLineId(itemWin?.line_id);
          console.log(itemWin);
          let codeWin = itemWin.gift_code;
          console.log(itemWin);
          let segmentsTemp = res.lucky_draw_list.list_lucky_draw[0].list_draw;
          console.log(segmentsTemp);
          localStorage.setItem(
            "GAME_DATA_LOCAL",
            JSON.stringify(res.lucky_draw_list.game_data)
          );
          localStorage.setItem("GIFT_WHEEL", JSON.stringify(itemWin));
          localStorage.setItem(LUOT_QUAY, res.remaining_draw);
          setGift(itemWin);
          const a = segmentsTemp.findIndex((x: any) => x.gift_code === codeWin);
          console.log(a);
          setwin(a + 1);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  const countDraw = async () => {
    const data = {
      sale_id: sale_id,
      campaign_id: campaign_id,
      line_id: lineId,
    };

    GameServices.CountDrawApi(data)
      .then((res: any) => {
        localStorage.setItem(LUOT_QUAY, res.remaining_draw);
        if (res.remaining_draw === 0) {
          // setTimeout(() => {
          const dataParams = {
            sale_id: sale_id,
            campaign_id: campaign_id,
          };
          GameServices.SendGiftApi(dataParams)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          // }, 6000);
        }

        localStorage.setItem("LUCKY_CODE", JSON.stringify(res));
        setDataCallUrbox(res.remaining_draw);
      })
      .catch((err) => {
        setIsError(true);
        setMessError(err);
        console.log(err);
      });
  };
  useEffect(() => {
    console.log(isAnnounce);
  }, []);
  const handleAgree = () => {
    setIsLoadButton(true);
    if (!isLoadButton) {
      const luot_quay = localStorage.getItem(LUOT_QUAY);
      if (luot_quay === "0") {
        navigation("/list-gift");
      } else {
        setPopupGift("change");
        setIsPopup(false);
        setIsLoadButton(false);
        setIsDisable(false);
      }
    }
  };
  const handleCloseError = () => {
    navigation("/games");
    setIsError(false);
  };
  return (
    <div>
      {isLoading ? <Loading /> : null}
      {segments && (
        <BackgroundGame img={game?.background_image_url}>
          <div className="absolute top-10 flex justify-center z-0 w-full px-5">
            <img
              src={game?.title_image_url}
              loading="lazy"
              className="w-10/12"
            />
          </div>
          {isOpenPopup ? null : (
            <div className="absolute top-[42%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-11/12">
              <div className="w-full relative z-30">
                <div id="bg" className="bg">
                  <div id="mainbox" className="mainbox">
                    <div
                      id="box"
                      className={`box nenvongquay
                       ${isAnnounce ? "hidden" : "block"}
                      `}
                      style={{
                        transform: `${transformBox}`,
                      }}
                      onClick={runWheel}
                    >
                      <div className="absolute top-1/2 -translate-y-1/2 w-full">
                        <img src={game?.wheel_image_url} className="w-full" />
                      </div>
                      <div
                        className="box1 font-regular-mon"
                        style={{ color: "#113962" }}
                      >
                        <span className="segment span1 color-1 wheel1">
                          <i id="id_7" className="item7 segments">
                            <h6>{segments[6]?.gift_shortname}</h6>
                            <img
                              src={segments[6]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                        <span className="segment span2 color-1">
                          <i id="id_3" className="item3 segments">
                            <h6>{segments[2]?.gift_shortname}</h6>
                            <img
                              src={segments[2]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                        <span className="segment span3 color-1">
                          <i id="id_5" className="item5 segments">
                            <h6>{segments[4]?.gift_shortname}</h6>
                            <img
                              src={segments[4]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                        <span className="segment span4 color-1">
                          <i id="id_1" className="item1 segments">
                            <h6>{segments[0]?.gift_shortname}</h6>
                            <img
                              src={segments[0]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                      </div>
                      <div
                        className="box2 font-regular-mon"
                        style={{ color: "#113962" }}
                      >
                        <span className="segment span1 color-2">
                          <i id="id_4" className="item4 segments">
                            <h6>{segments[3]?.gift_shortname}</h6>
                            <img
                              src={segments[3]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                        <span className="segment span2 color-2">
                          <i id="id_8" className="item8 segments">
                            <h6>{segments[7]?.gift_shortname}</h6>
                            <img
                              src={segments[7]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                        <span className="segment span3 color-2">
                          <i id="id_2" className="item2 segments">
                            <h6>{segments[1]?.gift_shortname}</h6>
                            <img
                              src={segments[1]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                        <span className="segment span4 color-2">
                          <i id="id_6" className="item6 segments">
                            <h6>{segments[5]?.gift_shortname}</h6>
                            <img
                              src={segments[5]?.gift_square_image_url}
                              loading="lazy"
                              alt="text"
                            />
                          </i>
                        </span>
                      </div>
                    </div>
                    <button
                      id="buttonRun"
                      className="spin z-20"
                      onClick={runWheel}
                      disabled={isSpinning}
                    >
                      <img
                        className="w-[105px]"
                        src={game?.needle_image_url}
                        alt="Image"
                      />
                    </button>
                  </div>
                </div>
                <div className="absolute -bottom-20 w-full flex justify-center">
                  <button
                    className="py-4 px-16 rounded-full"
                    style={{
                      backgroundColor: game?.button_color,
                      border: `2px solid ${game?.button_border_color}`,
                      color: game?.button_text_color,
                    }}
                  >
                    {dataCallUrbox > 0 ? (
                      <>
                        Bạn có{" "}
                        <span className="text-[24px] font-bold-so">
                          {dataCallUrbox}
                        </span>{" "}
                        lượt quay
                      </>
                    ) : (
                      "Hết lượt quay"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
          <PopupReceiveGift
            gift={gift}
            isShow={isOpenPopup}
            handleAgree={handleAgree}
            isLoadButton={isLoadButton}
          />
        </BackgroundGame>
      )}
      <PopupError
        labelBtn={"Đồng ý"}
        messError={messError}
        handleClose={handleCloseError}
        isShow={isError}
      />
    </div>
  );
}
